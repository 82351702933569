<template>
  <v-container class="pa-0 mx-0">
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="filtersApplied ? 'orange' : 'msaBlue'"
              class="white--text"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              v-blur
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </template>
          Filters
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col class="grow"> Filters </v-col>
            <v-col class="shrink">
              <v-btn
                icon
                dark
                @click="
                  dialog = false;
                  setFilters();
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row dense>
            <v-col cols="12" class="pb-0 font-weight-bold">
              Active Companies
            </v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="organizations"
                :value="localFilters.organizations"
                @change="localFilters.organizations = $event"
                clearable
                deletable-chips
                hide-details
                item-text="Name"
                item-value="ID"
                label="Select from active companies"
                multiple
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="pb-0 font-weight-bold"> Status </v-col>
            <v-col class="pb-2">
              <v-btn-toggle
                v-model="localFilters.status"
                dense
                mandatory
                color="msaBlue"
                style="width: 100%"
              >
                <v-btn style="width: 33%" class="text-capitalize" :value="2">
                  All
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize" :value="1">
                  Active
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize" :value="0">
                  Inactive
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="pb-0 font-weight-bold">
              Test Account
            </v-col>
            <v-col class="pb-2">
              <v-btn-toggle
                v-model="localFilters.testAccount"
                dense
                mandatory
                color="msaBlue"
                style="width: 100%"
              >
                <v-btn style="width: 33%" class="text-capitalize" :value="2">
                  Include
                </v-btn>
                <v-btn style="width: 33%" class="text-capitalize" :value="0">
                  Not Include
                </v-btn>
                <v-btn
                  style="width: 33%"
                  class="text-capitalize"
                  :value="1"
                  @click="localFilters.accountTypes = []"
                >
                  Only
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="mt-4 font-weight-bold"> Filter By </v-col>
            <v-col v-for="val in types" :key="val" cols="6" md="4" class="my-2">
              <v-checkbox
                v-model="localFilters.accountTypes"
                :label="labels[val]"
                color="msaBlue"
                dense
                hide-details
                :value="val"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="pb-0 font-weight-bold"> Report Date </v-col>
            <v-col>
              <v-select
                v-model="localFilters.reportRange"
                :items="[
                  { text: 'This Month', value: '0m' },
                  { text: 'Last Month', value: '1m' },
                  { text: 'Last 30 Days', value: '30d' },
                  { text: 'Last 3 Months', value: '3m' },
                  { text: 'Last 6 Months', value: '6m' },
                  { text: 'Last 12 Months', value: '12m' },
                ]"
                dense
                hide-details
                label="Pick the time range"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text class="mr-5" @click="clearFilter()">
            Clear
          </v-btn>
          <v-btn color="msaBlue white--text" @click="applyFilter">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: 'CompanyFilter',
  props: {
    filters: {
      type: Object,
    },
    organizations: {
      type: Array,
    },
  },
  computed: {
    filtersApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.COMPANY_LIST_DEFAULT_FILTERS)
      );
    },
  },
  data() {
    return {
      dialog: false,
      localFilters: { ...this.$constants.COMPANY_LIST_DEFAULT_FILTERS },
      types: [...Object.values(this.$constants.ACCOUNT_TYPES)],
      labels: {
        1: 'Basic',
        2: 'À La Carte',
        3: 'Free trial',
        4: 'Freemium',
        6: 'Peace Of Mind',
        7: 'Freedom To Operate',
        8: 'All Access',
        10: 'Training',
        11: 'Training Plus',
      },
    };
  },
  methods: {
    applyFilter() {
      this.localFilters.accountTypes.sort();
      this.$emit('applyFilters', this.localFilters);
      this.dialog = false;
    },
    clearFilter() {
      this.localFilters = { ...this.$constants.COMPANY_LIST_DEFAULT_FILTERS };
      this.applyFilter();
    },
    setFilters() {
      this.localFilters = { ...this.filters };
    },
  },
};
</script>
<style></style>
